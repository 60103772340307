















































import Vue from 'vue';
import { namespace } from 'vuex-class';
import AuthApi from '@/services/api/AuthApi';
import CommonRules from '@/validation/CommonRules';
import { Component } from 'vue-property-decorator';

const auth = namespace('authModule');

@Component
export default class Login extends Vue {
    public user: { email: string; password: string } = {
        email: '',
        password: ''
    };

    @auth.Mutation
    public setLoggedIn!: (isLoggedIn: boolean) => void;

    @auth.Mutation
    public setToken!: (token: string) => void;

    public rules = CommonRules;

    public valid: boolean = true;

    public disabledLogin: boolean = false;

    public async login(): Promise<void> {
        this.disabledLogin = true;

        try {
            const response = await (new AuthApi()).authenticate(this.user);
            const { access_token, is_admin } = response.data;

            if (!is_admin) {
                this.$store.commit('notificationModule/setSnackBarNotification', {
                    color: 'error',
                    msg: 'You have not permissions to login.'
                });

                return;
            }

            this.setToken(access_token);
            this.setLoggedIn(true);

            this.$router.push({ name: 'dashboard' });
        } catch (e) {
            console.error(e);

            this.$store.commit('notificationModule/setSnackBarNotification', {
                color: 'error',
                msg: e.response.data.error
            });
        } finally {
            this.disabledLogin = false;
        }
    }
}
